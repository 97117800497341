<template>
  <div>
    Beállítások oldal
  </div>
</template>
<script>
export default {

}
</script>
